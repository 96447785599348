#homehd {
    height: 800px;
    background: url(/src/Images/Rectangle\ 18.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

#header {
    justify-content: center;
    font-weight: 400;
    font-size: 30px;
    height: 70%;
}
.Mission {
    background-color: #d6c18b;
    background-image: linear-gradient(to top, #d6c18b, white);
    padding: 7%;
}
.Mission img {
    justify-self: center;
    text-align: center;
}
#misshd #a {
    font-weight: 700;
    font-size: 30px;
}
#misshd #b {
    font-size: 20px;
}
#gallery {
    background-color: #e5d7b1;
    padding: 3% 7%;
}
#gallery span button, #prog button{
    background-color: #008080;
    padding: 10px 30px;
    margin-top: 1.5%;
    font-family: Poppins;
    color: white;
    border: none;
    border-radius: 10px;
    font-weight: 600;
}
#quotes {
    padding: 3% 7%;
}

.cards-wrapper {
    display: flex;
    justify-content: center;
  }
.card img {
    max-width: 100%;
    max-height: 100%;
  }
.card {
    margin: 0 0.5em;
    box-shadow: 2px 6px 8px 0 rgba(22, 22, 26, 0.18);
    border: none;
    border-radius: 0;
  }
.carousel-inner {
    padding: 1em;
  }
.carousel-control-prev, .carousel-control-next {
    background-color: #e1e1e1;
    width: 5vh;
    height: 5vh;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
}

#prog {
    padding: 3% 7%;
}
#contn {
    display: flex;
    border-bottom: 2px solid #D9D9D9;
}
#prog #col6 { 
    display: flex;
    width: 50%;
}
#prog #col6l { 
    display: flex;
    width: 50%;
}
#ttxt {
    text-align: start;
}
#cont {
    background-color: #e2ded2;
    padding: 3% 7%;
}
#hmform {
    display: flex;
    padding: 3% 2%;
    border-radius: 10px;
}
#hmform .col-6 {
    display: grid;
}
#dt {
    padding-top: 2.5%;
}
#cont form {
    display: grid;
}
#cont form #fname {
    display: flex;
    margin-bottom: 2%;
}
#cont form span {
    justify-content: space-between;
}
#cont form span input {
    width: 49.5%;
}
#cont input {
    background-color: white;
    border-radius: 10px;
    padding: 2%;
    border: none;
    font-size: 20px;
}
#cont textarea {
    margin-bottom: 2%;
    border-radius: 10px;
    border: none;
    font-size: 20px;
    padding-left: 10px;
}
#cont button {
    padding: 2%;
    border-radius: 10px;
    border: none;
}
#cont input:focus {
    outline-style: none;
    box-shadow: none;
}
#cont textarea:focus {
    outline-style: none;
    box-shadow: none;
}

@media screen and (max-width: 780px) {
    #homehd span {
        font-size: 22px;
    }
    #homehd #break {
        display: none;
    }
    .Mission #a {
        font-size: 20px;
    }
    #misshd #b {
        font-size: 17px;
    }
    #prog {
        padding: inherit 4%;
        padding-bottom: 3%;
    }
    #contn {
        display: flow;
        place-content: center;
        border: none;
    }
    #prog #col6 {
        padding: 10% 0px;
        width: 100%;
        text-align: center;
        height: 100%;
        justify-content: center;
        display: grid;
        place-self: center;
        border-bottom: 1px solid #D9D9D9;
    }
    #prog #col6l {
        border-bottom: none;
        padding: 10% 0px;
        width: 100%;
        text-align: center;
        height: 100%;
        justify-content: center;
        display: grid;
        place-self: center;
    }
    #ttxt {
        text-align: center;
        padding-top: 20px;
    }
    #hmform {
        display: grid;
        border-radius: 0px;
    }
    #hmform .col-6 {
        width: 100%;
        display: contents;
        border-radius: 0px;
        padding: 3% 5%;
    }
    #cont {
        padding: 0px;
        margin: 0px 3%;
    }
    #hmform #mm1 span {
        padding-top: 3%;
    }
    
    .Home #hmform #mm1 #dt1 {
        padding-top: 0px;
        padding-bottom: 7%;
    }
    #cont form #fname {
        display: block;
        margin-bottom: 0px;
    }
    #cont form span input {
        width: 100%;
        margin-bottom: 2%;
    }
    .Home #gallery {
        padding: 3% 3%;
    }
}