#lecm {
    font-family: Satoshi;
    font-size: 20px;
    margin: 0px 25%
}

@media screen and (max-width: 780px) {
    #lecm {
        margin: 0px 5%;
    }
}