#errorpage .Navsec {
    background-color: black;
}
#errortxtt {
    justify-content: center;
    text-align: center;
    margin: 10% 2%;
    font-size: 30px;
}
#errortxtt a {
    color: #b1860e;
    font-weight: 600;
}
@media screen and (max-width: 780px) {
    #errortxtt {
        margin: 35% 3%;
        font-size: 20px;
        height: max-content;
    }
}